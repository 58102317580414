import Typography from '@mui/material/Typography';
import React, {useState,useEffect} from "react";

const createTolerancia = (nova_tolerancia, setTolerancia) => {
    const [ano, mes, dia, hora] = [
        nova_tolerancia.slice(0, 4),
        nova_tolerancia.slice(5, 7),
        nova_tolerancia.slice(8, 10),
        nova_tolerancia.slice(11, 19)
    ];

    const tolerancia = `Tolerância: ${dia}/${mes}/${ano} ${hora}`;
    setTolerancia(tolerancia);
};

const Tolerancia = ({nova_tolerancia}) => {

    const [tolerancia,setTolerancia] = useState('')

    
    useEffect(()=> {
        if(nova_tolerancia){
            createTolerancia(nova_tolerancia,setTolerancia)
        }
    },[]);


    return (
        <>
        <Typography variant="body2" color="text.secondary">
            {tolerancia}
        </Typography>
        </>
    )
}


export default Tolerancia;